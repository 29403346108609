import { Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs';
import RelativeTime from "dayjs/plugin/relativeTime";
import Utc from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import MainBody from '../Components/MainBody'
import { db } from '../firebase-config';
import { PostDocument } from '../interfaces'

dayjs.extend(RelativeTime);
dayjs.extend(Utc)
dayjs.extend(Timezone)
dayjs().format()

function Posts({ isAuth }: { isAuth: boolean }) {
  // const posts: PostsArray = [
  //   {title: "Testing", body: "testing body here", author: "Pat", datePublished: new Date().toISOString(), published: true}
  // ];

  const postsCollectionRef = collection(db, "posts");
  const [loaded, setLoaded] = useState<boolean>(false);
  const [posts, setPosts] = useState<Array<any>>([]);
  const [selectedPost, setSelectedPost] = useState<PostDocument|null>()

  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);

  const runUpdate = () => {
    const getPosts = async () => {
      if(isAuth){
        const data = await getDocs(query(postsCollectionRef, orderBy('datePublished', 'desc')));
        // console.log(data);
        setPosts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      } else {
        const q = query(postsCollectionRef, where("published", "==", true), orderBy('datePublished', 'desc'));
        const querySnapshot = await getDocs(q);
        setPosts(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      }
      setLoaded(true);
    };
    getPosts();
  };

  useEffect(() => {
    runUpdate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePostHandler = async (postId: string, cb: Function) => {
    const postDoc = doc(db, "posts", postId);
    await deleteDoc(postDoc);
    cb();
  };
  
  const handleCloseSaveAndClose = async (data: PostDocument, cb: Function) => {
    const postDoc = doc(db, "posts", data.id);
    setDoc(postDoc, data).then(() => {
      cb();
    });
  };

  const handleCloseRemove = () => {
    setRemoveConfirmOpen(false);
    setSelectedPost(null);
  };

  const handleCloseRemovePerformRemoval = (postId: string) => {
    deletePostHandler(postId, runUpdate);
    handleCloseRemove();
  };

  const tz = "America/New_York";

  const handleUpdateTitle = (event: any) => {
    let newPostDoc: any = {
      ...selectedPost
    };
    newPostDoc.title = event.target.value;
    setSelectedPost(newPostDoc);
  };

  const handleUpdateBody = (event: any) => {
    let newPostDoc: any = {
      ...selectedPost
    };
    newPostDoc.body = event.target.value;
    setSelectedPost(newPostDoc);
  };

  const handleUpdateAuthor = (event: any) => {
    let newPostDoc: any = {
      ...selectedPost
    };
    newPostDoc.author = event.target.value;
    setSelectedPost(newPostDoc);
  };

  const handleTogglePosted = () => {
    let newPostDoc: any = {
      ...selectedPost
    };
    newPostDoc.published = !selectedPost?.published;
    setSelectedPost(newPostDoc);
  }

  return (
    <MainBody title={"Posts"}>
      <div style={{ marginBottom: "2em"}} />
      <Grid container spacing={3}>
        {(!loaded) ? "Loading..." : (loaded && posts.length === 0) ? <Grid item xs={12}>
          <Card
            sx={{
              padding: '1em'
            }}
          >
            <Typography variant="h4">No Posts Yet</Typography>
            <Typography variant="body1">Check back soon for updates!</Typography>
          </Card>
        </Grid> : posts.map((post, idx) => {
          const fmtString = "YYYY-MM-DD [at] h:mm A";
          const fmtPostedTime = dayjs(post.datePublished).utc().local().tz(tz).format(fmtString);
          const ago = dayjs(post.datePublished).toNow(true);
          return (
            <Grid item xs={12} key={`post-${idx}`}>
              <Card
                sx={{
                  padding: '1em'
                }}
              >
                <Typography variant="h4">{post.title}</Typography>
                <Typography variant="caption" gutterBottom>Posted {fmtPostedTime.toString()}{post.author ? ` by ${post.author}` : ""} ({ago} ago){!post.published && " (UNPUBLISHED)"}</Typography>
                <div style={{ marginBottom: "1em" }} />
                <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>{`${post.body}`}</Typography>
                {isAuth && <div style={{marginTop: "1em"}}>
                  <Button
                    onClick={() => {
                      setSelectedPost(post);
                      setEditOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedPost(post);
                      setRemoveConfirmOpen(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>}
              </Card>
            </Grid>
          )
        })}
      </Grid>

      {/* Dialog(s) */}
      <Dialog
        open={editOpen}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Post"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Editing Post: "{selectedPost?.title}"
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-post-title"
              label="Title"
              defaultValue={selectedPost?.title}
              placeholder='Update Title'
              onChange={handleUpdateTitle}
            />
            <TextField
              id="new-post-author"
              label="Author (optional)"
              defaultValue={selectedPost?.author}
              placeholder='Update Author'
              onChange={handleUpdateAuthor}
            />
            <TextField
              required
              id="new-post-body"
              label="Body"
              defaultValue={selectedPost?.body}
              placeholder='Update Body'
              multiline
              onChange={handleUpdateBody}
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked={selectedPost?.published} onChange={handleTogglePosted} />} label="Make Post Publicly Visible?" />
              {selectedPost?.published && <Typography variant="caption">This post will be publicly visible.</Typography>}
            </FormGroup>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setEditOpen(false);
            setSelectedPost(null);
          }}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseSaveAndClose(selectedPost || {title: 'fixme', body: 'fixme', datePublished: '0', published: false, id: ""}, () => {
                setEditOpen(false);
                setSelectedPost(null);
                runUpdate();
              });
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={removeConfirmOpen}
        onClose={handleCloseRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm post deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to remove the post titled "{selectedPost?.title}"{" "}
            from the list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemove}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseRemovePerformRemoval(selectedPost?.id||"");
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MainBody>
  )
}

export default Posts