import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import MainBody from '../Components/MainBody'
import { Post } from '../interfaces';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase-config';

function NewPost({ isAuth, onAddCallback }: {isAuth: boolean, onAddCallback: Function}) {
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");
  const [published, setPublished] = useState<boolean>(false);
  const [datePublished, setDatePublished] = useState<Dayjs>(dayjs()); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [author, setAuthor] = useState<string|null>();

  const postsCollectionRef = collection(db, "posts");
  const createPost = async () => {
    let newDoc: Post = {
      title: title,
      body: body,
      datePublished: datePublished.startOf('minute').toISOString(),//.split("T")[0], // originally was startOf 'day'
      published: published,
    }
    if(author) {
      newDoc.author = author;
    }
    await addDoc(postsCollectionRef, newDoc);
    onAddCallback();
  };

  return (
    <MainBody title='New Post'>
      <Container>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h3">Create Post</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="caption">Add a new post.</Typography>
          </Box>
          <Box
            sx={{
              // textAlign: "center"
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Box
              component="form"
              // sx={{
              //   '& .MuiTextField-root': { m: 1, width: '50ch' },
              // }}
              noValidate
              autoComplete="off"
            >
              <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                <TextField
                  required
                  id="new-post-title"
                  label="Title"
                  // defaultValue=""
                  placeholder='Update Title'
                  onChange={(event: any) => {setTitle(event.target.value)}}
                />
                <TextField
                  id="new-post-author"
                  label="Author (optional)"
                  // defaultValue=""
                  placeholder='Update Author (optional)'
                  onChange={(event: any) => {setAuthor(event.target.value)}}
                />
                <TextField
                  required
                  id="new-post-body"
                  label="Body"
                  // defaultValue=""
                  multiline
                  placeholder='Body'
                  onChange={(event: any) => {setBody(event.target.value)}}
                />
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked={published} onChange={() => {setPublished(!published)}} />} label="Make Post Publicly Visible?" />
                  {published && <Typography variant="caption">This post will be publicly visible.</Typography>}
                </FormGroup>

              </div>
              <Button onClick={createPost}>Create Post</Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </MainBody>
  )
}

export default NewPost