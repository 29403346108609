import React from 'react'

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import "./Page.css";

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#530d0d',
    },
    secondary: {
      main: '#962424',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
    },
    background: {
      default: '#e2d9c4',
      paper: '#fbf9f0',
    },
    divider: '#962424',
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#ffde5a',
    },
    error: {
      main: '#962424',
    },
    success: {
      main: '#183318',
    },
  },
};

const theme = createTheme(themeOptions);

function Page(props: React.PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

export default Page