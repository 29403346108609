import { Typography } from '@mui/material'
import React from 'react'
import MainBody from '../Components/MainBody'

function NotFound404() {
  return (
    <MainBody title={"Page Not Found!"}>
      <Typography variant="h4">The page you tried could not be found.</Typography>
      <Typography variant="body1">Try going to the homepage and starting from there.</Typography>
    </MainBody>
  )
}

export default NotFound404