import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
// icons
import GradingIcon from '@mui/icons-material/Grading';

import "./NavAppBar.css";
import { NavBarItemArray } from '../interfaces';

const drawerWidth = 240;

export default function NavAppBar({isAuth, signUserOut}: {isAuth: boolean, signUserOut: any}) {
  const navPages: NavBarItemArray = [
  ];

  const mgmtNavPages: NavBarItemArray = [
    {route: '/new', text: 'New Post', icon: <GradingIcon />},
  ];

  const [open, setOpen] = React.useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawerOpen = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} enableColorOnDark>
        <Toolbar>
          {isAuth && 
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerOpen}
            >
            <MenuIcon />
            </IconButton>
          }
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" className={"header-link"}>
              Wedding Updates
            </Link>
          </Typography>
          <span>
            <Button href="https://heaneydavis.com" color="inherit">Return to Main Site</Button>
          </span>
          {
            !isAuth ? 
              <span>
                {/* <Button component={Link} to="/login" color="inherit">Login</Button> */}
              </span>
            :
              <span>
                <Button onClick={signUserOut} color="inherit">Log Out</Button>
              </span>
          }
        </Toolbar>
      </AppBar>
      {isAuth &&
      <SwipeableDrawer
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        open={open}
        disableSwipeToOpen={false}
        ModalProps={{
            keepMounted: true,
        }}
        sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
        >
        <Divider />
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
            <List>
            {navPages.map((navItem, index) => (
                <ListItem key={navItem.text} disablePadding>
                <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                    <ListItemIcon>
                    {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.text} />
                </ListItemButton>
                </ListItem>
            ))}
            </List>
            {/* <Divider /> */}
            {isAuth && (<div>
            <Divider />
            <List>
                <ListItem key="mgmt-title">
                <Typography>Management</Typography>
                </ListItem>
                {mgmtNavPages.map((navItem, index) => (
                <ListItem key={navItem.text} disablePadding>
                    <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                    <ListItemIcon>
                        {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.text} />
                    </ListItemButton>
                </ListItem>
                ))}
            </List>
            </div>)}
        </Box>
        </SwipeableDrawer>
      }
    </Box>
  );
}
