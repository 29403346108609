import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { auth, db, provider } from "../firebase-config";
import { signInWithPopup } from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { addDoc, collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import MainBody from '../Components/MainBody';

function Login({ isAuth, setIsAuth }: { isAuth: boolean, setIsAuth: React.Dispatch<React.SetStateAction<boolean>>}) {
  let navigate = useNavigate();
  const [showUnauthWarn, setShowUnauthWarn] = useState<boolean>(false);
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider).then((result) => {
      // console.log(result.user.uid);
      // console.log(result);
      const doAuthorizeCheck = async () => {
        // do request to db for returned uid, check if user in table
        const authUsersCollectionRef = collection(db, "authUsers");
        const q = query(authUsersCollectionRef, where("uid", "==", result.user.uid));
        const querySnapshot = await getDocs(q);
        if(querySnapshot.docs.length !== 1) {
          // if not, add to table,
          addDoc(authUsersCollectionRef, {
            authorized: false,
            attempts: 0,            
            uid: result.user.uid,
            displayName: result.user.displayName,
            email: result.user.email,
            metadata: {
              creationTime: result.user.metadata.creationTime,
              lastSignInTime: result.user.metadata.lastSignInTime,
            },
            phoneNumber: result.user.phoneNumber,
          }).then(() => {doAuthorizeCheck()}); // start over
        } else {
          let d = querySnapshot.docs[0];
          let d_id = d.id;
          let newDoc = { ...d.data() };
          // if so, check if authorized param is true
          if(newDoc.authorized) {
            // if auth == true, do the localstorage add, setauth true, and navigate /
            localStorage.setItem("isAuth", "true");
            setIsAuth(true);
            navigate("/");
            return
          }
          // else do nothing or display dialog
          // increment attempts
          newDoc.attempts = newDoc.attempts + 1;
          newDoc.metadata.lastSignInTime = result.user.metadata.lastSignInTime;
          // await addDoc(authUsersCollectionRef, newDoc);
          const characterDoc = doc(db, "authUsers", d_id);
          setDoc(characterDoc, newDoc).then(() => {
            // cb(); // insert callback stuff
            setShowUnauthWarn(true);
          });
        }
      }
      doAuthorizeCheck();
    });
  };

  useEffect(() => {
    if(isAuth){
      navigate("/");
    }
  }, [isAuth, navigate]);

  return (
    <MainBody title={"Login"}>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3" sx={{ textAlign: "center" }} gutterBottom>Sign in with Google</Typography>
        <Button variant="contained" onClick={signInWithGoogle}>Sign in with Google</Button>
      </Box>
      <Dialog
        open={showUnauthWarn}
        onClose={() => {}} // only perform close if button in dialog clicked
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          Unauthorized Login Detected
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            This attempt has been logged.
          </DialogContentText>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowUnauthWarn(false);
          }}>My Bad</Button>
        </DialogActions>
      </Dialog>
    </MainBody>
  )
}

export default Login