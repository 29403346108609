import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavAppBar from './Components/NavAppBar';
import Page from './Page';
import ProtectedRoute, { ProtectedRouteProps } from './Components/ProtectedRoute';
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
import Posts from './Pages/Posts';
import Login from './Pages/Login';
import NotFound404 from './Pages/NotFound404';
import NewPost from './Pages/NewPost';


function App() {
  const [isAuth, setIsAuth] = useState<boolean>(JSON.parse(localStorage.getItem('isAuth') || "false") === true);
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: isAuth,
    authenticationPath: '/',
  };
  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('isAuth');
      setIsAuth(false);
      window.location.pathname = "/";
    });
  };

  return (
    <Page>
      <Router>
        <nav>
          <NavAppBar isAuth={isAuth} signUserOut={signUserOut} />
        </nav>
        <Routes>
          <Route path="/" element={<Posts isAuth={isAuth} />} />
          <Route path="/new" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<NewPost isAuth={isAuth} onAddCallback={() => {window.location.assign("/")}} />} />} />
          <Route path="/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth} />} />
          {/*  */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </Router>
    </Page>
  );
}

export default App;
