import { Container } from '@mui/material'
import React, { useEffect } from 'react'

type Props = {
  children: React.ReactNode,
  title?: string,
}

function MainBody(props: Props) {
  useEffect(() => {
    if(props.title && props.title?.toString().length > 0 && !props.title.toString().includes("undefined")) {
      document.title = props.title + " | Heaney-Davis Wedding Updates" || "Heaney-Davis Wedding Updates";
    }
  }, [props.title]);
  return (
    <Container>
      {props.children}
    </Container>
  )
}

export default MainBody